import { useGetPlaylistsSteezyQuery } from "services/graphql";

export function useGetPlaylistsSteezy() {
  const perPage = 10;
  const {
    data,
    fetchMore,
    loading: loadingPlaylistsSteezy,
    error: errorPlaylistsSteezy,
  } = useGetPlaylistsSteezyQuery({
    variables: {
      pagination: {
        first: perPage,
      },
    },
    notifyOnNetworkStatusChange: true,
  });

  const pageInfoPlaylistsSteezy = data?.playlistsSteezy?.pageInfo;
  const playlistsSteezy = data?.playlistsSteezy?.edges.map(edge => edge.node);

  const fetchMorePlaylistsSteezy = () => {
    fetchMore({
      variables: {
        pagination: {
          first: perPage,
          after: pageInfoPlaylistsSteezy?.endCursor,
        },
      },
      updateQuery: (previousResult, { fetchMoreResult }) => {
        const previousEntry = previousResult.playlistsSteezy;
        const newEdges = fetchMoreResult.playlistsSteezy.edges;

        return {
          playlistsSteezy: {
            ...fetchMoreResult.playlistsSteezy,
            edges: [...previousEntry.edges, ...newEdges],
          },
        };
      },
    });
  };

  return {
    isFetchingInitialPlaylistsSteezy:
      !playlistsSteezy && loadingPlaylistsSteezy,
    playlistsSteezy,
    errorPlaylistsSteezy,
    pageInfoPlaylistsSteezy,
    fetchMorePlaylistsSteezy,
    loadingPlaylistsSteezy,
  };
}
